import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ProjectCards from './ProjectCards';
import BlogCards from './BlogCards';
import EmptyRowOfCards from './EmptyRowOfCards';
import { Box } from '@material-ui/core';

const CardsColumns = () => {
  const data = useStaticQuery(
    graphql`
      query homeCardsDataQuery {
        allSanityProject(limit: 3, sort: { fields: _createdAt }) {
          nodes {
            _id
            projectName
            projectShortDescription
            projectImage {
              alt
              caption
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            projectSocials {
              socialProjectLink
              social {
                socialName
              }
            }
            projectSlug {
              current
            }
          }
        }
        allSanityBlogPost(limit: 3, sort: { fields: _createdAt }) {
          nodes {
            _id
            blogEntryTitle
            blogShortDescription
            blogHeadingImage {
              alt
              caption
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            blogSlug {
              current
            }
          }
        }
      }
    `
  );
  const projects = data.allSanityProject.nodes;
  const blogPosts = data.allSanityBlogPost.nodes;
  let projectSection;
  let blogSection;
  if (!Array.isArray(projects) || !projects.length) {
    projectSection = (
      <EmptyRowOfCards typeOfCards={'projects'} backgroundColor={'burlywood'} />
    );
  } else {
    projectSection = <ProjectCards projects={projects} />;
  }

  if (!Array.isArray(blogPosts) || !blogPosts.length) {
    blogSection = (
      <EmptyRowOfCards typeOfCards={'blog posts'} backgroundColor={'#5D737E'} />
    );
  } else {
    blogSection = <BlogCards blogPosts={blogPosts} />;
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      {/* Projects Row of Cards */}
      {projectSection}
      {/* Blog Posts Row of Cards */}
      {blogSection}
    </Box>
  );
};

export default CardsColumns;
