import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as GatsbyLink, navigate } from 'gatsby';
import styled from 'styled-components';

const useStyles = makeStyles({
  root: {
    maxWidth: 275,
    marginRight: (customStyles) => customStyles.marginRight,
    marginBottom: (customStyles) => customStyles.marginBottom,
  },
  media: {
    height: 140,
  },
  linkSpacing: {
    marginLeft: 6,
  },
});

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
`;

const RenderSocialLink = ({ socialLink, socialName, typeOfCard, slug }) => {
  if (socialLink !== null) {
    if (typeOfCard === 'projectCard') {
      return (
        <Link href={socialLink} target='_blank' rel='noopener'>
          Go to {socialName}
        </Link>
      );
    }
    if (typeOfCard === 'blogCard') {
      return (
        <GatsbyLinkStyled
          className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'
          to={'/' + slug}
        >
          Go to Blog
        </GatsbyLinkStyled>
      );
    }
  } else {
    return null;
  }
};

const cardNavigateTo = (link) => {
  if (link !== null) {
    navigate(link);
  }
};

const MaterialCards = ({
  title,
  description,
  image,
  socials,
  type,
  slug,
  customStyle,
}) => {
  const classes = useStyles(customStyle);

  let cardLink;

  if (type === 'projectCard') {
    cardLink = `/projects/${slug}`;
  } else if (type === 'blogCard') {
    cardLink = slug;
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => cardNavigateTo(cardLink)}>
        <CardMedia
          component='img'
          className={classes.media}
          image={image.source}
          title={image.caption}
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Typography className={classes.linkSpacing}>
          {type === 'projectCard' && (
            <RenderSocialLink
              typeOfCard={'projectCard'}
              socialLink={socials.socialProjectLink}
              socialName={socials.social.socialName}
            />
          )}
          {type === 'blogCard' && (
            <RenderSocialLink typeOfCard={'blogCard'} slug={cardLink} />
          )}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default MaterialCards;
