import React from 'react';
import styled from 'styled-components';
import LetterboxdIcon from '../../images/svg/letterboxd-icon-dark.svg';
import LinkedInIcon from '../../images/svg/linkedin-icon.svg';
import GithubIcon from '../../images/svg/github-icon.svg';

const IconsDiv = styled.div``;

const GithubIconSvg = styled(GithubIcon)`
  margin-right: 10px;
  /* filter: invert(100%); */
`;
const LinkedInIconSvg = styled(LinkedInIcon)`
  margin-right: 10px;
`;

const SocialLinks = () => {
  return (
    <IconsDiv>
      <a
        href='https://github.com/bojackhorseman0309'
        name='github'
        rel='noopener noreferrer'
        target='_blank'
      >
        <GithubIconSvg
          width={25}
          height={25}
          aria-labelledby='github'
          role='presentation'
        />
      </a>
      <a
        href='https://www.linkedin.com/in/alonso-araya-calvo-83a572168/'
        name='linkedin'
        rel='noopener noreferrer'
        target='_blank'
      >
        <LinkedInIconSvg
          width={25}
          height={25}
          aria-labelledby='linkedin'
          role='presentation'
        />
      </a>
      <a
        href='https://letterboxd.com/alonsoaraya/'
        name='letterboxd'
        rel='noopener noreferrer'
        target='_blank'
      >
        <LetterboxdIcon
          width={25}
          height={25}
          aria-labelledby='letterboxd'
          role='presentation'
        />
      </a>
    </IconsDiv>
  );
};

export default SocialLinks;
