import React from 'react';
import styled from 'styled-components';

const ProjectsColumnDiv = styled.div`
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const ObjectRowHeadingStyled = styled.h1`
  color: white;
  text-align: center;
  text-transform: capitalize;
`;

const EmptyMessageHeadingStyled = styled.h2`
  color: white;
  text-align: center;
  font-weight: 400;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 20px;
    width: 270px;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 22px;
    width: 350px;
  }
`;

const EmptyRowOfCards = ({ typeOfCards, backgroundColor }) => {
  return (
    <ProjectsColumnDiv backgroundColor={backgroundColor}>
      <ObjectRowHeadingStyled>{typeOfCards}</ObjectRowHeadingStyled>
      <EmptyMessageHeadingStyled>
        There are no {typeOfCards} available right now to show 😔
      </EmptyMessageHeadingStyled>
    </ProjectsColumnDiv>
  );
};

export default EmptyRowOfCards;
