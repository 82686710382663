import { Box, Grid, Typography } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import SocialLinks from './SocialLinks';

const StaticImageStyle = {
  borderRadius: '50%',
};

const NameAndProfileSection = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      py={['40px', '40px', '40px', '75px', '75px']}
    >
      <Box mx={'35px'} width={{ lg: '30%', xl: '30%' }} mb='15px'>
        <Typography
          variant='h2'
          component={'h1'}
          style={{ marginBottom: '15px' }}
        >
          Welcome!
        </Typography>
        <Typography variant='body1'>
          This is my portfolio site and maybe sometimes my playground. Check out
          my recent blog posts and projects below.
        </Typography>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          style={{ marginTop: '20px' }}
        >
          <Typography variant='body1' style={{ marginRight: '20px' }}>
            Alonso Araya
          </Typography>
          <SocialLinks />
        </Grid>
      </Box>
      <StaticImage
        src='../../images/bojack.png'
        alt='My profile picture'
        width={150}
        height={150}
        imgStyle={StaticImageStyle}
      />
    </Box>
  );
};

export default NameAndProfileSection;
