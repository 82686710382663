import React from 'react';
import NameAndProfileSection from '../components/HomePage/NameAndProfilePicSection';
import CardsColumns from '../components/HomePage/CardsColumns';

const Home = () => {
  return (
    <>
      {/* First Section, Name and Profile Picture */}
      <NameAndProfileSection />
      {/* Section Where Projects and Blog columns go */}
      <CardsColumns />
    </>
  );
};

export default Home;
