import React from 'react';
import styled from 'styled-components';
import MaterialCards from '../Shared/MaterialCards';
import { getSrc } from 'gatsby-plugin-image';
import { Link as GatsbyLink } from 'gatsby';
import { isMobile } from 'react-device-detect';
import { Box, Typography } from '@material-ui/core';

const GatsbyLinkDivStyled = styled.div`
  text-align: center;
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  text-align: center;
`;

const ProjectCards = ({ projects }) => {
  return (
    <Box bgcolor='burlywood' py='50px' width='100%'>
      <Typography
        variant='h3'
        component='h2'
        style={{ textAlign: 'center', color: 'white' }}
      >
        Projects
      </Typography>
      <Box
        display='flex'
        mt='20px'
        mb='40px'
        justifyContent='center'
        flexDirection={['column', 'column', 'column', 'row', 'row']}
        alignItems={{
          xs: 'center',
          sm: 'center',
          md: 'center',
          lg: 'normal',
          xl: 'normal',
        }}
      >
        {projects.map((project, i) => {
          const projectImageSrc = getSrc(project.projectImage.asset);
          const projectImage = {
            source: projectImageSrc,
            caption: project.projectImage.caption,
            alt: project.projectImage.alt,
          };
          let customStyle = { marginRight: 20 };
          if (projects[i + 1] === undefined) {
            customStyle.marginRight = 0;
          }

          if (isMobile) {
            customStyle.marginRight = 0;
            customStyle.marginBottom = 10;
          }

          return (
            <MaterialCards
              title={project.projectName}
              description={project.projectShortDescription}
              image={projectImage}
              socials={project.projectSocials}
              type={'projectCard'}
              key={project._id}
              customStyle={customStyle}
              slug={project.projectSlug.current}
            />
          );
        })}
      </Box>
      <GatsbyLinkDivStyled>
        <GatsbyLinkStyled to='/projects'>
          See More Projects &#8594;
        </GatsbyLinkStyled>
      </GatsbyLinkDivStyled>
    </Box>
  );
};

export default ProjectCards;
